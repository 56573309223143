import parse, {
  domToReact,
  attributesToProps,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';
import { ImageProps } from 'next/image';
import dynamic from 'next/dynamic';
import {
  Box,
  ListItem,
  UnorderedList,
  OrderedList,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import WysiwygStyles from './WysiwygStyles';
import { MhyChakraProps } from '../types';

const Link = dynamic(() => import('./Link'));
const Image = dynamic(() => import('./Image'));
const Heading = dynamic(() => import('./Heading'));
const Paragraph = dynamic(() => import('./Paragraph'));

interface Props {
  html: string;
  optionModifier?: 'restrictHeadings';
}

const headersToRestrict = ['h1', 'h2', 'h3', 'h4', 'h5'];

function toAspectRatioTuple(ratio: string): [number, number] | false {
  const tuple = ratio
    .replace(new RegExp('\\b1\\b'), '1/1')
    .split('/')
    .map((i) => parseInt(i, 10))
    .slice(0, 2);

  if (tuple.length !== 2 || Number.isNaN(tuple[0]) || Number.isNaN(tuple[1])) {
    return false;
  }

  return [tuple[0], tuple[1]];
}

const getOptions = (optionModifier: Props['optionModifier']) => {
  const options: HTMLReactParserOptions = {
    replace: ({ attribs, name, children }: Element) => {
      if (
        optionModifier === 'restrictHeadings' &&
        headersToRestrict.includes(name)
      ) {
        return (
          <Heading as="h4" className="is-style-h4" mt={8} mb={8}>
            {children && domToReact(children, options)}
          </Heading>
        );
      }
      switch (name) {
        case 'p': {
          // NOTE: This filters out all classNames such as 'has-drop-cap' etc that GB provides. To re-enable use {...attributesToProps(attribs || {})}
          return (
            <Paragraph>{children && domToReact(children, options)}</Paragraph>
          );
        }
        case 'a': {
          const linkProps = attributesToProps(attribs || {});
          return (
            <Link {...linkProps}>
              {children && domToReact(children, options)}
            </Link>
          );
        }
        case 'h1': {
          // h1 not allowed
          return (
            <Heading as="h2">
              {children && domToReact(children, options)}
            </Heading>
          );
        }
        case 'h2': {
          return (
            <Heading as="h2">
              {children && domToReact(children, options)}
            </Heading>
          );
        }
        case 'h3': {
          return (
            <Heading as="h3">
              {children && domToReact(children, options)}
            </Heading>
          );
        }
        case 'h4': {
          return (
            <Heading as="h4">
              {children && domToReact(children, options)}
            </Heading>
          );
        }
        case 'h5': {
          return (
            <Heading as="h5">
              {children && domToReact(children, options)}
            </Heading>
          );
        }
        case 'ul': {
          return (
            <UnorderedList textStyle="lg" mb={5} pl="1em">
              {children && domToReact(children, options)}
            </UnorderedList>
          );
        }
        case 'ol': {
          return (
            <OrderedList
              {...attributesToProps(attribs || {})}
              textStyle="lg"
              mb={5}
              pl="1em"
            >
              {children && domToReact(children, options)}
            </OrderedList>
          );
        }
        case 'li': {
          return (
            <ListItem mb={5} {...attributesToProps(attribs || {})}>
              {children && domToReact(children, options)}
            </ListItem>
          );
        }
        case 'table': {
          return (
            <TableContainer>
              <Table>{children && domToReact(children, options)}</Table>
            </TableContainer>
          );
        }
        case 'th': {
          return (
            <Th _first={{ paddingLeft: 0 }} _last={{ paddingRight: 0 }}>
              {children && domToReact(children, options)}
            </Th>
          );
        }
        case 'tr': {
          return <Tr>{children && domToReact(children, options)}</Tr>;
        }
        case 'td': {
          return (
            <Td _first={{ paddingLeft: 0 }} _last={{ paddingRight: 0 }}>
              {children && domToReact(children, options)}
            </Td>
          );
        }
        case 'thead': {
          return <Thead>{children && domToReact(children, options)}</Thead>;
        }
        case 'tbody': {
          return <Tbody>{children && domToReact(children, options)}</Tbody>;
        }
        case 'tfoot': {
          return <Tfoot>{children && domToReact(children, options)}</Tfoot>;
        }
        case 'figure': {
          const { class: className } = attribs || {};
          if (className?.includes('aligncenter')) {
            return (
              <Box
                as="figure"
                textAlign="center"
                sx={{
                  '> *:first-of-type': {
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  },
                }}
              >
                {children && domToReact(children, options)}
              </Box>
            );
          }
          return (
            <Box as="figure">{children && domToReact(children, options)}</Box>
          );
        }
        case 'img': {
          const {
            src,
            alt,
            style,
            width: attributeWidth,
            height: attributeHeight,
            ...rest
          } = attributesToProps(attribs || {});
          const {
            width = attributeWidth || 600,
            height = attributeHeight || 400,
            objectFit,
            aspectRatio,
          } = (style as {
            width: ImageProps['width'];
            height: ImageProps['height'];
            objectFit: ImageProps['objectFit'];
            aspectRatio: string;
          }) || {
            width: attributeWidth || '600',
            height: attributeHeight || '400',
          };

          const aspectRatioTuple =
            aspectRatio && (aspectRatio.includes('/') || aspectRatio === '1')
              ? toAspectRatioTuple(aspectRatio)
              : false;

          // 600x400 is the default fallback. width and height come from WP unless something is broken
          return (
            <Image
              {...{
                ...(aspectRatioTuple && { aspectRatio: aspectRatioTuple }),
                src,
                alt,
                objectFit,
                width,
                height,
                ...rest,
              }}
            />
          );
        }
        default:
          return false;
      }
    },
  };
  return options;
};

const HtmlContent = ({
  html,
  optionModifier,
  ...rest
}: Props & Omit<MhyChakraProps, 'variant'>) =>
  !html ? null : (
    <WysiwygStyles {...rest}>
      {parse(html, getOptions(optionModifier))}
    </WysiwygStyles>
  );

export default HtmlContent;
